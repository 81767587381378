import React from 'react';
import logo from '../assets/bg-logo.png';
import Box from '@mui/material/Box';
import '../pages/HomePage.css';

const HomePage = () => {
  console.log(logo);

  return (
    <Box className="homepage">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </Box>
    
  );
};

export default HomePage;
