import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TermsAndConditions from './pages/TermsAndConditions';
import TechnicalSpecification from './pages/TechnicalSpecification';
import CodeOfConduct from './pages/CodeOfConduct';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Standard-terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/Technical-Specification" element={<TechnicalSpecification />} />
        <Route path="/Code-of-Conduct" element={<CodeOfConduct />} />

      </Routes>
    </Router>
  );
}

export default App;
